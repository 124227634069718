<template >
  <div id="policy-detail">
    <h3 class="title">{{datas.title}}</h3>
    <div class="detail-info">
      <div class="info-item">发布单位：{{datas.publisher_full_name}}</div>
      <div class="info-item">适用地域：{{datas.apply_to_area_type}}</div>
      <div class="info-item">发布时间：{{datas.created_at}}</div>
      <div class="info-item" v-html="datas.content">{{datas.content}}</div>
    </div>
    <div class="detail-foot">
      <div>想要查看更多政策详情，</div>
      <div>请微信扫描下方二维码查看</div>
      <div class="foot-item">
        <img
          width="180"
          height="180"
          src="https://cdn.weitianshi.cn/space_file/qrcode/20200302/8IWjtnCbsD0yo3n6RN2eFqEeRRzNHPQW5e5ce0d940d0f.jpg"
          alt
        />
      </div>
    </div>
  </div>
</template>
<script>
import { async } from "q";
export default {
  name: "PolicyDetail",
  props: { id: {} },
  data() {
    return {
      datas: {},
      qrPath: "" //二维码地址
    };
  },
  created() {
    this.getPolicy();
    this.getQR();
  },
  methods: {
    //政策详情
    async getPolicy() {
      const res = await this.$http.get(
        this.URL.adminUrl.PolicyDetail + this.id,
        {}
      );
      console.log(res, "政策详情政策详情");
      this.datas = res.data.data;
      if (this.datas.apply_to_area_type == 1) {
        this.datas.apply_to_area_type = "全国";
      }
      if (this.datas.apply_to_area_type == 2) {
        this.datas.apply_to_area_type = "其他省市";
      }
      if (this.datas.apply_to_area_type == 3) {
        this.datas.apply_to_area_type = "机构";
      }
    },
    //政策-二维码
    async getQR() {
      const res = await this.$http.get(this.URL.adminUrl.PolicyQr + this.id, {
        params: {
          path: `pages/plicyDetails/plicyDetails?id=${this.id}`,
          width: 280,
          app_id: "wxd7e43c82a4ad404c"
        }
      });
      console.log(res, "政策-二维码");
      this.qrPath = res.data.data;
    }
  }
};
</script>
<style lang="less" scoped>
#policy-detail {
  height: 100%;
  max-width: 640px;
  width: 100%;
  padding: 10px;
  .title {
    text-align: center;
    margin: 50px;
  }
  .info-item {
    margin: 20px;
  }
  .detail-foot {
    margin: 90px 20px 0;
    text-align: right;
  }
  .foot-item {
    margin: 10px;
  }
}
</style>